import { LatLngExpression } from 'leaflet';

/* tslint:disable:no-any */
declare global {
  interface Window {
    env: any;
  }
}
/* tslint:enable:no-any */

function readEnv(name, defaultValue = undefined) {
  if (window.env && window.env[name]) {
    return window.env[name];
  } else if (process.env && process.env[name]) {
    return process.env[name];
  } else {
    return defaultValue;
  }
}

export const SENTRY_URL = readEnv('REACT_APP_SENTRY_URL');
export const SENTRY_TRACE_SAMPLE_RATE = parseFloat(readEnv('REACT_APP_SENTRY_TRACE_SAMPLE_RATE', 0.2));
export const ENVIRONMENT = readEnv('REACT_APP_ENVIRONMENT');
export const GEO_LOCATOR_API_URL = readEnv('REACT_APP_GEO_LOCATOR_API_URL', 'https://nominatim.openstreetmap.org');
export const OFFERS_DEMO_LOGIN = readEnv('REACT_APP_OFFERS_DEMO_LOGIN', false) === 'true';
export const DEMO_EMAIL = readEnv('REACT_APP_DEMO_EMAIL', '');
export const DEMO_PASSWORD = readEnv('REACT_APP_DEMO_PASSWORD', '');
// Version is automatically provided through variables set in the package.json scripts.
export const APP_VERSION = readEnv('REACT_APP_VERSION', '');
export const ANALYTICS_BASE_URL = readEnv('REACT_APP_ANALYTICS_BASE_URL', '');
export const ANALYTICS_SITE_ID = readEnv('REACT_APP_ANALYTICS_SITE_ID', '');
export const ANALYTICS_SECURE_COOKIE = readEnv('REACT_APP_ANALYTICS_SECURE_COOKIE', true) !== 'false';
export const MUI_LICENSE_KEY = readEnv('REACT_APP_MUI_LICENSE_KEY', '');

export const ENABLE_REDUX_DEV_TOOLS = false;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const IS_TEST_ENVIRONMENT = readEnv('NODE_ENV') === 'test';

const BACKEND_BASE_URL = readEnv('REACT_APP_BACKEND_BASE_URL');
export const API_URL = `${BACKEND_BASE_URL}/api/v1/`;

/**
 * The delay after which a spinner should be shown when interacting with an element.
 */
export const INTERACTION_INDICATOR_DELAY = 100;

/**
 * The default coordinates for Germany used in the map components.
 */
export const GERMANY_COORDINATES: LatLngExpression = [51.1657, 10.4515];
