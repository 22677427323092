import { ContractorProject } from './contractorProject';
import { TimestampedModel } from './generic';
import { User } from './user';

export interface DailyTurnover {
  date: string;
  turnover: number;
}

export enum UserStatus {
  CHECKED_IN = 'checkedIn',
  NOT_CHECKED_IN = 'notCheckedIn',
  NEVER_CHECKED_IN = 'neverCheckedIn',
}

export interface ContractorUserStatus extends TimestampedModel {
  user: User;
  lastCheckIn?: {
    id: number;
    contractorProject: Pick<ContractorProject, 'id' | 'name' | 'address' | 'city' | 'postalCode' | 'country'>;
    checkedInAt: string;
    checkedOutAt: string | null;
    checkout: number | null;
  } | null;
  status: UserStatus;
}

export interface Kpi {
  imagesRunningMonth: number;
  imagesRunningWeek: number;
  imagesRunningYear: number;
  imagesToday: number;
  imagesTotal: number;
  name: string;
  priceProgressOngoing: number;
  projectsOngoing: number;
  projectsInPreparation: number;
  projectsFinished: number;
}
