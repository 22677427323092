export const stringifyParams = (params?: { [key: string]: any }) => {
  if (!params) {
    return '';
  }

  const queryString = new URLSearchParams(params).toString();
  return `?${queryString}`;
};

/**
 * Returns the decoded value of the `next` URL search parameter or the default value if the parameter is not present.
 * If decoding fails, the default value is returned.
 */
export const getNextURLSearchParam = (urlSearchParams: URLSearchParams, defaultValue: string) => {
  const nextParam = urlSearchParams.get('next');
  if (nextParam === null) {
    return defaultValue;
  }
  try {
    return decodeURIComponent(nextParam);
  } catch (_) {
    return defaultValue;
  }
};
