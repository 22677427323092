import React, { FC } from 'react';
import { Avatar as MuiAvatar, AvatarProps } from '@mui/material';
import { styled } from '@mui/system';

import TestIDs from 'src/TestIDs.json';
import { CurrentUser, LegalEntityUser, User } from 'src/types';
import { getInitials } from 'src/utils/formatting';

interface UserInfoAvatarProps extends AvatarProps {
  size?: 'small' | 'normal';
  user: User | CurrentUser | LegalEntityUser;
}

const Avatar = styled(MuiAvatar)<{ size: 'small' | 'normal' }>(({ size, theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: size === 'small' ? 32 : 40,
  width: size === 'small' ? 32 : 40,
  fontSize: size === 'small' ? '0.8rem' : '1.0rem',
}));

/**
 * Shows the user's avatar, if available. Otherwise it shows the initials.
 */
export const UserInfoAvatar: FC<UserInfoAvatarProps> = ({ user, size = 'normal', ...props }) => {
  const initials = getInitials(user.firstName, user.lastName);

  return (
    <Avatar
      {...props}
      size={size}
      data-test-id={TestIDs.components.userInfo.avatar}
      src={user.avatar}
      title={user.fullName || user.legalEntity.name}
      alt={user.fullName || user.legalEntity.name}
    >
      {initials}
    </Avatar>
  );
};

export default UserInfoAvatar;
