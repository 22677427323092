import { User } from './user';

export interface TimestampedModel {
  createdAt: string;
  modifiedAt: string;
}

export interface CommonInfoModel extends TimestampedModel {
  createdBy?: User;
  modifiedBy?: User;
}

export interface BaseModelChoice {
  id: number;
}

export interface UserModelChoice extends BaseModelChoice {
  fullName: string;
}

export interface ContractorProjectModelChoice extends BaseModelChoice {
  name: string;
  projectNumber: string;
}

export interface ConstructionProjectModelChoice extends BaseModelChoice {
  name: string;
}

export interface CustomerModelChoice extends BaseModelChoice {
  companyName: string | null;
  recipient: string | null;
  firstName: string | null;
  lastName: string | null;
  customerNumber: string | null;
}

export interface ExportModelField {
  name: string;
  verboseName: string;
}

export enum AutocompleteModel {
  CONSTRUCTION_PROJECT = 'general_contractors.ConstructionProject',
  CONTRACTOR_PROJECT = 'contractors.ContractorProject',
  USER = 'users.User',
  CUSTOMER = 'customers.Customer',
}

export type IDDict<T> = { [id: number]: T };

export enum WeekDay {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum ProjectStatus {
  FINISHED = 'finished',
  IN_PREPARATION = 'inPreparation',
  ONGOING = 'ongoing',
}
